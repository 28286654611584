import { Component, OnInit, ChangeDetectorRef, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from './services/navigation.service';
import { ShopService } from './services/shop.service';
import { Order } from './models/order.model';
import { Page } from './models/page.model';
import { Title } from '@angular/platform-browser';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { WelcomeDialogComponent } from './welcome-dialog/welcome-dialog.component';
import { MatDialog } from '@angular/material/dialog';

declare var ga: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'ngx-nooba-shop';
  activeCols$: any;
  activeCols = null;
  order$: any;
  order: Order = null;

  col0$: any;
  col0 = null;
  col1$: any;
  col1 = null;
  col2$: any;
  col2 = null;

  showCookies = false;


  constructor(
    translate: TranslateService,
    private nav: NavigationService,
    private shop: ShopService,
    private cdr: ChangeDetectorRef,
    private angulartics: Angulartics2GoogleGlobalSiteTag,
    private router: Router,
    private titleService: Title,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    this.angulartics.startTracking();

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    const lang = localStorage.getItem('lang');
    if (lang) {
      translate.use(lang);
    } else {
      translate.use('de');
    }

    // domain and port for SSR in this example is static. Use i.e. environment files to use appropriate dev/prod domain:port
    const domain = (isPlatformServer(platformId)) ? 'http://localhost:4000/' : '';


  }

  ngOnInit() {
    //this.openDialog()
    let cookies = localStorage.getItem('cookies');
    if (!cookies) {
      this.showCookies = true;
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (isPlatformBrowser) {
          if ((window as any).gtag) {
            (window as any).gtag('config', 'UA-142147031-1', {
              page_path: event.url,
              page_title: this.titleService.getTitle()
            });
          }
        }

      }
    });

    this.shop.getPages().subscribe(
      (p: Array<Page>) => {
        this.shop.pages.next(p);
      }
    );
  }

  closeCookies() {
    localStorage.setItem('cookies', 'true');
    this.showCookies = false;
  }


  ngAfterViewInit() {
    this.col0$ = this.nav.col0.subscribe(c => {
      this.col0 = c;
      this.cdr.detectChanges();
    });
    this.col1$ = this.nav.col1.subscribe(c => {
      this.col1 = c;
      this.cdr.detectChanges();
    });
    this.col2$ = this.nav.col2.subscribe(c => {
      this.col2 = c;
      this.cdr.detectChanges();
    });
    this.order$ = this.shop.order.subscribe((o) => {
      this.order = o;
      this.cdr.detectChanges();
    });
  }


  openDialog() {
    const dialogRef = this.dialog.open(WelcomeDialogComponent, {panelClass: 'welcome-dialog'
    });
    dialogRef.afterClosed().subscribe((res) => {
      
    });
  }

  isMobile() {
    return window.innerWidth < 1150;
  }
}
